.ag-header {
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #989898;
}

.ag-cell-label-container {
  padding: 0 10px;
}

.ag-header-cell-resize {
  border-left: 1px solid rgb(200, 200, 200);
}

.ag-cell {
  border-right: 1px solid rgb(200, 200, 200);
  padding: 0 4px;
}

.custom-sort-icon {
  font-size: 12px;
  color: #333;
  margin-left: 4px;
}
